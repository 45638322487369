html,
* {
  box-sizing: border-box;
  position: relative;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.flex {
  flex: 1;
}

.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}

a {
  text-decoration: none;
  color: inherit;
}
.stencil {
  font-family: 'Black Ops One' !important;
  text-transform: uppercase;
}
.serif {
  font-family: 'Cinzel', serif !important;
}
.sans {
  font-family: 'Source Sans Pro', sans !important;
}
.text {
  color: #555;
}
.img-text {
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}
.no-select-text {
  user-select: none;
}
fieldset legend {
  padding-left: 2rem;
  padding-right: 2rem;
}
.space-v {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.space-h {
  margin-left: 1rem;
  margin-right: 1rem;
}
.space-r {
  margin-right: 1rem;
}
.space-l {
  margin-left: 1rem;
}
.small-header {
  font-size: 1rem !important;
  padding-bottom: 0.25rem !important;
  padding-top: 0.25rem !important;
  font-weight: bold !important;
}
.summary-row {
  margin: 0;
  padding-bottom: 0.25rem !important;
  padding-top: 0.25rem !important;
}
.flex-wrapper {
  display: flex;
}
.flex-wrapper.vertical {
  flex-direction: column;
}
.flex-0 {
  flex: 0;
}
.flex-1,
.flex-spacer {
  flex: 1;
}
.video-wrapper {
  background-color: #ccc;
  width: 100%;
  padding-top: 56.25%; /* 1:1 Aspect Ratio */
  position: relative; /* If you want text inside of it */
}
.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
hr {
  border: 0;
  border-top: 1px solid #ccc;
}
.swal2-shown {
  z-index: 9999 !important;
}
.StripeElement {
  padding: 1rem 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.StripeElement--invalid {
  border-color: #fa755a;
}
.submit-attempted .StripeElement--empty {
  border-color: #fa755a !important;
}
